import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, FloatButton, Image, Pagination, Progress, Switch } from "antd";
import {
  fetchCustomerById,
  getInviteCustomerById,
  setIsStaffCustomerApi,
  updateCustomer,
} from "../../../../../api/customer";
import iconGold from "../../../../../assets/images/iconGold.svg";
import iconMember from "../../../../../assets/images/iconMember.svg";
import iconPlatinum from "../../../../../assets/images/iconPlatinum.svg";
import iconSilver from "../../../../../assets/images/iconSilver.svg";
import user from "../../../../../assets/images/user.png";
import LoadingPagination from "../../../../../components/paginationLoading";
import InputCustom from "../../../../../components/textInputCustom";
import { formatMoney } from "../../../../../helper/formatMoney";
import { errorNotify, successNotify } from "../../../../../helper/toast";
import i18n from "../../../../../i18n";
import { loadingAction } from "../../../../../redux/actions/loading";
import { getLanguageState } from "../../../../../redux/selectors/auth";
import "./index.scss";
// core components

const DetailsProfile = ({ id }) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState("other");
  const [rank, setRank] = useState("");
  const [checkRank, setCheckRank] = useState("");
  const [data, setData] = useState([]);
  const [rankPoint, setRankPoint] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dataInvite, setDataInvite] = useState([]);
  const [totalInvite, setTotalInvite] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isStaff, setIsStaff] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector(getLanguageState);

  useEffect(() => {
    dispatch(loadingAction.loadingRequest(true));
    fetchCustomerById(id)
      .then((res) => {
        setData(res);
        setName(res?.full_name);
        setMail(res?.email);
        setBirthday(res?.birthday ? res?.birthday?.slice(0, 10) : "");
        setGender(res?.gender);
        setIsStaff(res?.is_staff);
        dispatch(loadingAction.loadingRequest(false));
      })
      .catch((err) => dispatch(loadingAction.loadingRequest(false)));

    getInviteCustomerById(id, 0, 20)
      .then((res) => {
        setDataInvite(res?.data);
        setTotalInvite(res?.totalItem);
      })
      .catch((err) => {});
  }, [id, dispatch]);

  useEffect(() => {
    if (data?.rank_point < 100) {
      setRank(`${i18n.t("member", { lng: lang })}`);
      setCheckRank("member");
    } else if (data?.rank_point >= 100 && data?.rank_point < 300) {
      setRank(`${i18n.t("silver", { lng: lang })}`);
      setCheckRank("silver");
    } else if (data?.rank_point >= 300 && data?.rank_point < 1500) {
      setRank(`${i18n.t("gold", { lng: lang })}`);
      setCheckRank("gold");
    } else if (data?.rank_point > 1500) {
      setRank(`${i18n.t("platinum", { lng: lang })}`);
      setCheckRank("platinum");
    }
    setRankPoint(data?.rank_point);
  }, [data, lang]);

  const updateUser = () => {
    dispatch(loadingAction.loadingRequest(true));
    const birth = moment(new Date(birthday)).toISOString();
    updateCustomer(data?._id, {
      phone: data?.phone,
      email: mail,
      full_name: name,
      gender: gender,
      birthday: birthday !== "" ? birth : "",
    })
      .then((res) => {
        successNotify({
          message: `${i18n.t("update_success_info", { lng: lang })}`,
        });
        fetchCustomerById(id)
          .then((res) => {
            setData(res);
            setName(res?.full_name);
            setMail(res?.email);
            setBirthday(res?.birthday ? res?.birthday?.slice(0, 10) : "");
            setGender(res?.gender);
            setIsStaff(res?.is_staff);
            dispatch(loadingAction.loadingRequest(false));
          })
          .catch((err) => {
            errorNotify({
              message: err?.message,
            });
            dispatch(loadingAction.loadingRequest(false));
          });
      })
      .catch((err) => {});
  };

  const onIsStaff = useCallback(() => {
    setIsLoading(true);
    setIsStaffCustomerApi(id, { is_staff: isStaff ? false : true })
      .then((res) => {
        fetchCustomerById(id)
          .then((res) => {
            setData(res);
            setName(res?.full_name);
            setMail(res?.email);
            setBirthday(res?.birthday ? res?.birthday?.slice(0, 10) : "");
            setGender(res?.gender);
            setIsStaff(res?.is_staff);
            setIsLoading(false);
          })
          .catch((err) => setIsLoading(false));
      })
      .catch((err) => {
        errorNotify({
          message: err?.message,
        });
        setIsLoading(false);
      });
  }, [isStaff, id]);

  const age = moment().diff(data?.birthday, "years");

  const onChange = (page) => {
    setCurrentPage(page);
    const lengthData = dataInvite.length < 20 ? 20 : dataInvite.length;
    const start = page * lengthData - lengthData;
    getInviteCustomerById(id, start, 20)
      .then((res) => {
        setDataInvite(res?.data);
        setTotalInvite(res?.totalItem);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="div-profile-customer">
        <div className="div-infomation-name">
          <div className="div-image-customer">
            <Image
              className="img-avatar"
              src={data?.avatar ? data?.avatar : user}
            />
            <div className="div-name">
              <p className="text-name">{data?.full_name}</p>
              <p className="text-invite">
                {`${i18n.t("code_customer", { lng: lang })}`}: {data?.id_view}
              </p>
              {data?.birthday && (
                <p className="text-invite">
                  {`${i18n.t("age", { lng: lang })}`}: {age}
                </p>
              )}
              <p className="text-invite">
                {`${i18n.t("code_invite", { lng: lang })}`}: {data?.invite_code}
              </p>
            </div>
          </div>
          <div className="div-rank-pay-member">
            <div className="div-member">
              <p className="text-title">G-pay</p>
              <div className="div-rank-customer">
                <p className="text-money">{formatMoney(data?.pay_point)}</p>
              </div>
            </div>
            <div className="div-member">
              <p className="text-title">{`${i18n.t("member", {
                lng: lang,
              })}`}</p>
              <div className="div-rank-customer">
                <p className="text-money">{rank}</p>
                <Image
                  preview={false}
                  className="icon-rank"
                  src={
                    checkRank === "silver"
                      ? iconSilver
                      : checkRank === "gold"
                      ? iconGold
                      : checkRank === "platinum"
                      ? iconPlatinum
                      : iconMember
                  }
                />
                <p className="text-money">
                  {rankPoint} {`${i18n.t("point", { lng: lang })}`}
                </p>
              </div>
            </div>
            <div className="div-member">
              <p className="text-title">Total Price</p>
              <div className="div-rank-customer">
                <p className="text-money">{formatMoney(data?.total_price)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="div-infomation">
          <h3 className="">{`${i18n.t("info", { lng: lang })}`}</h3>
          <div className="div-detail-infomation">
            <div className="div-left">
              <InputCustom
                title={`${i18n.t("full_name", { lng: lang })}`}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ width: "80%" }}
              />
              <InputCustom
                title={`${i18n.t("gender", { lng: lang })}`}
                select={true}
                value={gender}
                onChange={(e) => setGender(e)}
                options={[
                  {
                    value: "other",
                    label: `${i18n.t("other", { lng: lang })}`,
                  },
                  {
                    value: "male",
                    label: `${i18n.t("male", { lng: lang })}`,
                  },
                  {
                    value: "female",
                    label: `${i18n.t("female", { lng: lang })}`,
                  },
                ]}
                style={{ width: "80%" }}
              />
              <InputCustom
                title={`${i18n.t("phone", { lng: lang })}`}
                value={data?.phone}
                disabled={true}
                style={{ width: "80%" }}
              />
            </div>
            <div className="div-right">
              <InputCustom
                title={`${i18n.t("birthday", { lng: lang })}`}
                type="date"
                value={birthday}
                onChange={(e) => {
                  setBirthday(e.target.value);
                }}
                style={{ width: "100%" }}
              />
              <InputCustom
                title={`${i18n.t("Email", { lng: lang })}`}
                type="email"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                style={{ width: "100%" }}
              />
              <div className="mt-3 div-staff">
                <p className="label-staff">Nhân viên</p>
                <Switch
                  style={{
                    width: 50,
                    backgroundColor: isStaff ? "#00cf3a" : "",
                  }}
                  checked={isStaff}
                  onClick={onIsStaff}
                />
              </div>
            </div>
          </div>
          <Button className="btn-update-customer" onClick={updateUser}>
            {`${i18n.t("update", { lng: lang })}`}
          </Button>
        </div>
      </div>

      <div className="div-container-invite-code">
        <p className="title-invite">{`${i18n.t("recent_referrals", {
          lng: lang,
        })}`}</p>
        {dataInvite.length > 0 ? (
          <>
            <div className="div-list-invite">
              {dataInvite.map((item, index) => {
                return (
                  <div key={index} className="div-item-invite">
                    <Image src={user} className="img-customer-invite" />
                    <div className="div-invite-progress">
                      <div className="div-row-info">
                        <div>
                          <div className="div-name-invite">
                            <p className="title-name">{`${i18n.t("name", {
                              lng: lang,
                            })}`}</p>
                            <p className="title-colon">: </p>
                            <p className="text-name ml-2">{item?.full_name}</p>
                          </div>
                          <div className="div-name-invite">
                            <p className="title-name">{`${i18n.t("sdt", {
                              lng: lang,
                            })}`}</p>
                            <p className="title-colon">: </p>
                            <p className="text-name ml-2">{item?.phone}</p>
                          </div>
                          <div className="div-name-invite">
                            <p className="title-name">{`${i18n.t("code", {
                              lng: lang,
                            })}`}</p>
                            <p className="title-colon">: </p>
                            <p className="text-name ml-2">{item?.id_view}</p>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p className="text-date-create">
                            {`${i18n.t("date_create", { lng: lang })}`}:{" "}
                            {moment(item?.date_create).format("DD-MM-YYYY")}
                          </p>
                          <p className="text-date-create">
                            {`${i18n.t("Số đơn", { lng: lang })}`}:{" "}
                            {item?.total_done_order}
                          </p>
                          <p className="text-date-create">
                            {`${i18n.t("Tổng tiền", { lng: lang })}`}:{" "}
                            {formatMoney(item?.total_price)}
                          </p>
                        </div>
                      </div>

                      <Progress
                        percent={
                          item?.total_order === 0
                            ? 33
                            : item?.total_order !== 0 &&
                              item?.total_done_order === 0
                            ? 66
                            : 100
                        }
                        strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                      />
                      <p
                        className={
                          item?.total_order === 0
                            ? "text-step"
                            : item?.total_order !== 0 &&
                              item?.total_done_order === 0
                            ? "text-step"
                            : "text-step-done"
                        }
                      >
                        {item?.total_order === 0
                          ? `${i18n.t("step_three", { lng: lang })}`
                          : item?.total_order !== 0 &&
                            item?.total_done_order === 0
                          ? `${i18n.t("last_step", { lng: lang })}`
                          : `${i18n.t("successful_introduction", {
                              lng: lang,
                            })}`}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-1 div-pagination p-2">
              <p>
                {`${i18n.t("total", { lng: lang })}`}: {totalInvite}
              </p>
              <div>
                <Pagination
                  current={currentPage}
                  onChange={onChange}
                  total={totalInvite}
                  showSizeChanger={false}
                  pageSize={20}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="text-no-invite">Chưa có người giới thiệu</p>
        )}
      </div>

      <FloatButton.BackTop />
      {isLoading && <LoadingPagination />}
    </>
  );
};

export default DetailsProfile;
