import { useEffect, useState } from "react";
import "./styles.scss";
import { getInviteCollaborator } from "../../../../../../../api/collaborator";
import { Pagination, Progress, Table } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { getLanguageState } from "../../../../../../../redux/selectors/auth";
import i18n from "../../../../../../../i18n";

const InviteCollaborator = ({ id }) => {
  const [totalCustomer, setTotalCustomer] = useState();
  const [totalCollaborator, setTotalCollaborator] = useState();
  const [dataCustomer, setCustomer] = useState([]);
  const [dataCollaborator, setCollaborator] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tab, setTab] = useState("collaborator");
  const lang = useSelector(getLanguageState);
  useEffect(() => {
    getInviteCollaborator(id, 0, 20)
      .then((res) => {
        setCustomer(res?.customer);
        setCollaborator(res?.collaborator);
        setTotalCustomer(res?.total_customer);
        setTotalCollaborator(res?.total_collaborator);
      })
      .catch((err) => {});
  }, [id]);

  const columns =
    tab === "collaborator"
      ? [
          {
            title: `${i18n.t("date_create", { lng: lang })}`,
            render: (data) => {
              return (
                <div className="div-date-create-invite">
                  <p className="text-create">
                    {moment(new Date(data?.date_create)).format("DD/MM/YYYY")}
                  </p>
                  <p className="text-create">
                    {moment(new Date(data?.date_create)).format("HH:mm")}
                  </p>
                </div>
              );
            },
          },
          {
            title: `${i18n.t("code_order", { lng: lang })}`,
            render: (data) => <p className="text-id-view">{data?.id_view}</p>,
          },
          {
            title: `${i18n.t("name", { lng: lang })}`,
            render: (data) => {
              return (
                <div className="div-name-invite-collaborator">
                  <p className="text-name">{data?.full_name}</p>
                  <p className="text-name">{data?.phone}</p>
                </div>
              );
            },
          },
        ]
      : [
          {
            title: `${i18n.t("date_create", { lng: lang })}`,
            render: (data) => {
              return (
                <div className="div-date-create-invite">
                  <p className="text-create">
                    {moment(new Date(data?.date_create)).format("DD/MM/YYYY")}
                  </p>
                  <p className="text-create">
                    {moment(new Date(data?.date_create)).format("HH:mm")}
                  </p>
                </div>
              );
            },
          },
          {
            title: `${i18n.t("code_order", { lng: lang })}`,
            render: (data) => <p className="text-id-view">{data?.id_view}</p>,
          },
          {
            title: `${i18n.t("name", { lng: lang })}`,
            render: (data) => {
              return (
                <div className="div-name-invite-collaborator">
                  <p className="text-name">{data?.full_name}</p>
                  <p className="text-name">{data?.phone}</p>
                </div>
              );
            },
          },
          {
            title: "Giai đoạn",
            render: (data) => (
              <Progress
                percent={
                  data?.total_order === 0
                    ? 33
                    : data?.total_order !== 0 && data?.total_done_order === 0
                    ? 66
                    : 100
                }
                strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              />
            ),
          },
        ];

  const onChange = (page) => {
    setCurrentPage(page);
    const dataLength =
      tab === "collaborator"
        ? dataCollaborator.length < 20
          ? 20
          : dataCollaborator.length
        : dataCustomer.length < 20
        ? 20
        : dataCustomer.length;
    const start = page * dataLength - dataLength;
    getInviteCollaborator(id, start, 20)
      .then((res) => {
        setCustomer(res?.customer);
        setCollaborator(res?.collaborator);
        setTotalCustomer(res?.totalCustomer);
        setTotalCollaborator(res?.totalCollaborator);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="div-tab-invite-collaborator">
        {DATA_TAB?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                item?.value === tab ? "div-item-tab-select" : "div-item-tab"
              }
              onClick={() => setTab(item?.value)}
            >
              <p className="text-tab">{`${i18n.t(item?.title, {
                lng: lang,
              })}`}</p>
            </div>
          );
        })}
      </div>
      <div className="mt-3">
        <Table
          dataSource={tab === "collaborator" ? dataCollaborator : dataCustomer}
          columns={columns}
          pagination={false}
        />
      </div>
      <div className="div-pagination p-2">
        <p>
          {`${i18n.t("total", { lng: lang })}`}:{" "}
          {tab === "collaborator" ? totalCollaborator : totalCustomer}
        </p>
        <div>
          <Pagination
            current={currentPage}
            onChange={onChange}
            total={tab === "collaborator" ? totalCollaborator : totalCustomer}
            showSizeChanger={false}
            pageSize={20}
          />
        </div>
      </div>
    </div>
  );
};

export default InviteCollaborator;

const DATA_TAB = [
  { title: "invite_collaborator", value: "collaborator" },
  { title: "invite_customer", value: "customer" },
];
