import {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoRemove,
  IoTrendingUp,
  IoTrendingDown,
} from "react-icons/io5";

const icons = {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoRemove,
  IoTrendingUp,
  IoTrendingDown,
};

export default icons;
